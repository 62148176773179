import { AuthProvider } from "@pankod/refine-core";
import { authSchema } from "./auth-schema";

export const TOKEN_KEY = "refine-auth";

export const authProvider: AuthProvider = {
  login: async (data) => {
    console.log(data);
    //const apiKey = data.apiKey;
    const entries: { [id: string]: string } = {};

    for (const schemaItem of Object.values(authSchema)) {
      if (schemaItem.type != "apiKey")
        throw new Error("Unsupported auth schema item type");
      const apiKey = data[schemaItem.name];
      const givenKeyValid = apiKey && apiKey.length > 0; // keys.find((item) => item.apiKey === apiKey);

      if (givenKeyValid) {
        entries[schemaItem.name] = apiKey;
        // check if api key valid (when possible)
        if (schemaItem.checkUrl) {
          console.log("Check");
          const res = await checkApiKey(schemaItem.checkUrl, apiKey);
          console.log({ res });
          if (!res) {
            console.log("Check fail");

            return await Promise.reject(
              new Error(`Invalid ${schemaItem.name}`)
            );
          }
        }
      } else {
        if (schemaItem.required)
          return await Promise.reject(new Error(`Invalid ${schemaItem.name}`));
      }
    }

    localStorage.setItem(TOKEN_KEY, JSON.stringify(entries));
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve({
      id: 1,
    });
  },
};

async function checkApiKey(url: string, apik: string) {
  try {
    const data = await fetch(url, { headers: { "X-API-KEY": apik } });
    return data.status == 200;
  } catch (ex) {}
  return false;
}
