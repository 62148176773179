import { List, RefreshButton } from "@pankod/refine-antd";
import {
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useNavigation,
} from "@pankod/refine-core";
import { ResourceList } from "common/resource-list/ResourceList";
import { IConnectRequest } from "connect-request/ConnectRequestInterfaces";
import {
  deviceListProps,
  deviceListSearchProps,
  IDevice,
} from "./DevicesInterfaces";

export const DevicesList: React.FC = () => {
  const invalidate = useInvalidate();
  const refreshList = () => {
    invalidate({ resource: "devices", invalidates: ["list"] });
  };
  const apiUrl = useApiUrl();
  const { mutate, isLoading } = useCustomMutation<IConnectRequest>();

  const { show, showUrl } = useNavigation();

  return (
    <List
      headerButtons={
        <RefreshButton
          onClick={() => {
            refreshList();
          }}
        ></RefreshButton>
      }
    >
      <ResourceList
        listProps={deviceListProps}
        listSearchProps={deviceListSearchProps}
        hideCreate
        hideEdit
        hideShow
        customClick={[
          {
            label: (record) => (
              <a href={showUrl("devices", record.address)}>Show</a>
            ),
            callback(record) {
              show("devices", record.address);
            },
          },
          {
            label: "connect",
            callback(record: IDevice) {
              if (window.confirm("Connect device?")) {
                mutate({
                  // resource: "conenction-request",
                  url: apiUrl + "/connect-disconnected",
                  method: "post",
                  values: { addresses: [record.address] } as IConnectRequest,
                  successNotification(data: any, values, resource) {
                    return {
                      message: `request created ${data?.data?.id}`,
                      type: "success",
                      undoableTimeout: 20010,
                    };
                  },
                });
              }
            },
          },
        ]}
      />
    </List>
  );
};
