import { CopyOutlined } from "@ant-design/icons";
import { Button, Space } from "@pankod/refine-antd";

export const ShortenedWithExplorerUrl = (props: {
  str?: string;
  path?: string;
}) => {
  if (!props.str) return <>-</>;
  const shorten = (text: string) => `${text.slice(0, 4)}...${text.slice(-4)}`;
  const short = props.str.length > 8 ? shorten(props.str ?? "") : props.str;
  const url = process.env.REACT_APP_EXPLORER_URL! + props.path + props.str;

  return (
    <>
      <Button
        style={{ marginRight: 5 }}
        size="small"
        icon={<CopyOutlined />}
        onClick={() =>
          window.navigator.clipboard.writeText(props.str ?? "undefined")
        }
      />
      <a href={url} target={"_blank"}>
        {short}
      </a>
    </>
  );
};
