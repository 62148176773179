import { EnumWithTranslation } from "common/EnumWithTranslation";
import { ResourceListFilter } from "common/resource-list/ResourceListFilter";
import { ResourceListItem } from "common/resource-list/ResourceListItem";
import {
  ConnectRequestStatus,
  ConnectRequestStatusList,
  ConnectRequestStatusTrans,
} from "./ConnectRequestEnums";

export interface IConnectRequest {
  id: number;
  jobHandlerId?: number;
  providers?: string; // list of providers to use or none for all

  /** List of addresses to be force connected */
  addresses?: string[];
  /** Include all devices with status change < given value; usefull for iot server restart */
  includeAllBeforeTstamp?: number;

  status: ConnectRequestStatus;
  all_sms: number;
  done_sms: number;
  fail_sms: number;
  error?: string;

  createdAt: number;
  updatedAt: number;
}

export const connectRequestListProps: ResourceListItem<IConnectRequest>[] = [
  "id",
  {
    type: "custom",
    dataIndex: "status",
    renderer(data, record, index) {
      return (
        <EnumWithTranslation
          value={data}
          translations={ConnectRequestStatusTrans}
        />
      );
    },
  },
  "allSms",
  "doneSms",
  "failSms",
  "providers",
  { type: "date", dataIndex: "createdAt" },
  { type: "date", dataIndex: "updatedAt" },
];

export const connectRequestListSearchProps: ResourceListFilter[] = [
  "id",
  "providers",
  {
    type: "enum",
    name: "Status",
    dataIndex: "status",
    transl: ConnectRequestStatusList.map((x: string) => ({
      name: ConnectRequestStatusTrans[x as ConnectRequestStatus]?.text ?? "",
      val: x,
    })),
  },
];
