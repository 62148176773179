import { EnumWithTranslation } from "common/EnumWithTranslation";
import { ResourceListFilter } from "common/resource-list/ResourceListFilter";
import { ResourceListItem } from "common/resource-list/ResourceListItem";
import {
  ConnectSmsStatus,
  ConnectSmsStatusList,
  ConnectSmsStatusTrans,
} from "./ConnectSmsEnums";

export interface IConnectSms {
  id: number;
  connectRequest: number;
  provider: string; // list of providers to use or none for all
  imei: string;
  iccid?: string;
  error?: string;
  smsid?: string;
  status: ConnectSmsStatus;
  createdAt: number;
  updatedAt: number;
}

export const connectSmsListProps: ResourceListItem<IConnectSms>[] = [
  "id",
  {
    type: "custom",
    dataIndex: "status",
    renderer(data, record, index) {
      return (
        <EnumWithTranslation
          value={data}
          translations={ConnectSmsStatusTrans}
        />
      );
    },
  },
  "provider",
  "imei",
  "iccid",
  "error",
  "smsid",
  { type: "date", dataIndex: "createdAt" },
  { type: "date", dataIndex: "updatedAt" },
];

export const connectSmsListSearchProps: ResourceListFilter[] = [
  "id",
  "provider",
  "imei",
  {
    type: "enum",
    name: "Status",
    dataIndex: "status",
    transl: ConnectSmsStatusList.map((x: string) => ({
      name: ConnectSmsStatusTrans[x as ConnectSmsStatus]?.text ?? "",
      val: x,
    })),
  },
];
