import { Card, Checkbox, Collapse, Descriptions } from "@pankod/refine-antd";
import { TextWithWhitespaces } from "common/TextWithWhitespaces";
import { TracingData, TracingSpan } from "common/tracing/TracingDataTypes";
import { useState } from "react";
import { ProgressRange } from "utils";
import "./TracingAntdStyleFixes.css";

export const TracingMain = (props: {
  trace: TracingData;
  entryStart: number;
}) => {
  const hideForksTime = 5000000;
  const [hideForks, setHideForks] = useState(true);

  const minOffset = Math.min(
    ...props.trace.spans.map((x) => x.startTime),
    props.entryStart
  );
  const maxOffset = hideForks
    ? Math.max(
        ...props.trace.spans
          .map((x) => ({ start: x.startTime, duration: x.duration }))
          .filter((x) => x.start < minOffset + hideForksTime)
          .map((x) => x.start + x.duration)
      )
    : Math.max(...props.trace.spans.map((x) => x.startTime + x.duration));

  const sortedSpans = props.trace.spans.sort(
    (a, b) => a.startTime - b.startTime
  );

  const prepSpansData = sortedSpans.map(
    (x) =>
      ({
        duration: x.duration,
        start: x.startTime,
        name: props.trace.processes[x.processID].serviceName,
        span: x,
      } as SpanPropsPrepared)
  );
  prepSpansData.unshift({
    duration: 1000,
    start: minOffset,
    name: "Tx create",
  });

  return (
    <>
      <Descriptions>
        <Descriptions.Item label="Flow duration">
          {(maxOffset - minOffset) / 1000} ms
        </Descriptions.Item>
        <Descriptions.Item label="Hide potential forks">
          <Checkbox
            checked={hideForks}
            onChange={(val) =>
              val.target.checked != hideForks
                ? setHideForks(val.target.checked)
                : null
            }
          />
        </Descriptions.Item>
      </Descriptions>
      {/* <TextWithWhitespaces text={JSON.stringify(props.trace, undefined, 2)} /> */}
      <Collapse>
        {prepSpansData.map((x) => (
          <Collapse.Panel
            className="no-padding"
            key={x.name + x.start}
            header={
              <SpanHeader {...x} offsets={{ min: minOffset, max: maxOffset }} />
            }
            style={
              x.start > maxOffset ? { opacity: 0.3, background: "#eee" } : {}
            }
          >
            <SpanBody {...x} offsets={{ min: minOffset, max: maxOffset }} />
          </Collapse.Panel>
        ))}
      </Collapse>
    </>
  );
};

export type SpanPropsPrepared = {
  duration: number;
  start: number;
  name: string;
  span?: TracingSpan;
};

export type SpanProps = {
  offsets: { min: number; max: number };
  start: number;
  duration: number;
  name: string;
  span?: TracingSpan;
};

export const SpanHeader = (props: SpanProps) => {
  const { isError, desc } = spanIsError(props.span);
  const logs = spanLogs(props.span, props.offsets);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <span
            style={{
              display: "inline-block",
              minWidth: 200,
              fontWeight: "bold",
              color: isError ? "red" : undefined,
            }}
          >
            {props.name}
          </span>
          <span style={{ display: "inline-block", minWidth: 200 }}>
            @ {((props.start - props.offsets.min) / 1000).toFixed(0)} ms
          </span>
          <span style={{ display: "inline-block", minWidth: 200 }}>
            {(props.duration / 1000).toFixed()} ms
          </span>
        </div>
        <div style={{ color: isError ? "red" : undefined }}>{desc}</div>
      </div>
      <ProgressRange
        start={props.start - props.offsets.min}
        stop={props.start - props.offsets.min + props.duration}
        total={props.offsets.max - props.offsets.min}
        points={logs}
      />
    </>
  );
};

export const SpanBody = (props: SpanProps) => {
  const { isError, desc } = spanIsError(props.span);

  return (
    <Card>
      {desc && (
        <div style={{ color: isError ? "red" : undefined }}>
          Status: <b>{desc}</b>
        </div>
      )}
      {props.span &&
        props.span.logs.map((x) => (
          <>
            <div>
              @ {((x.timestamp - props.start) / 1000).toFixed(0)} ms -{" "}
              {x.fields.map((field) => field.value)}
            </div>
          </>
        ))}
      <div>@ {(props.duration / 1000).toFixed(0)} ms - Span end</div>
    </Card>
  );
};

const spanIsError = (span?: TracingSpan) => {
  if (!span) return { isError: false };
  const status = span.tags.find((x) => x.key == "otel.status_code")?.value;
  const statusIsError = status == "ERROR";

  const statusDesc = span.tags.find(
    (x) => x.key == "otel.status_description"
  )?.value;

  return { isError: statusIsError, desc: statusDesc };
};

const spanLogs = (
  span: TracingSpan | undefined,
  offsets: { min: number; max: number }
) => {
  if (!span || !span.logs) return [];
  return span.logs.map((x) => ({
    point: x.timestamp - offsets.min,
    label: x.fields.map((field) => field.value).join(", "),
  }));
};
