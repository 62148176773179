import {
  useForm,
  Form,
  Create,
  Edit,
  Input,
  InputNumber,
  Checkbox,
  Show,
  Descriptions,
  Divider,
  Button,
  RefreshButton,
} from "@pankod/refine-antd";
import { useApiUrl, useCustom, useShow } from "@pankod/refine-core";
import { EnumWithTranslation } from "common/EnumWithTranslation";
import { TracingData } from "common/tracing/TracingDataTypes";
import { IBcTransaction } from "./BcTransactionInterfaces";
import { bcStatusTrans, iotStatusTrans } from "./BcTransEnums";
import { TracingMain } from "./components/TracingMain";
// import Trace from "jaeger-react-trace-component";

export const BcTransactionShow = () => {
  const { queryResult } = useShow<IBcTransaction>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const apiUrl = useApiUrl("aggregator");

  const traceDataFetch = useCustom({
    method: "get",
    url: `${apiUrl}/trace/${record?.txid}`,
    dataProviderName: "aggregator",
  });
  const traceData: TracingData | undefined =
    traceDataFetch.data?.data?.data?.[0];

  const getStart = () => {
    let txStart = null;
    try {
      const tx = JSON.parse(record?.tx ?? "{}");
      txStart = tx.timestamp * 1000;
    } catch {}
    return txStart ?? (record?.createdAt ?? 0) * 1000;
  };

  return (
    <>
      <Show>
        {!isLoading && (
          <Descriptions title={"Aggregated"} column={1}>
            <Descriptions.Item label="txID">{record?.txid}</Descriptions.Item>

            <Descriptions.Item label="device">
              {record?.device}
            </Descriptions.Item>
            <Descriptions.Item label="sender">
              {record?.sender}
            </Descriptions.Item>
            <Descriptions.Item label="organisation">
              {record?.organisation}
            </Descriptions.Item>
            <Descriptions.Item label="tx status">
              <EnumWithTranslation
                translations={bcStatusTrans}
                value={record?.txStatus ?? ""}
              />
            </Descriptions.Item>
            <Descriptions.Item label="iot status">
              <EnumWithTranslation
                translations={iotStatusTrans}
                value={record?.iotStatus ?? ""}
              />
            </Descriptions.Item>
            <Descriptions.Item label="created">
              {new Date((record?.createdAt ?? 0) - 0).toLocaleString("pl")}
            </Descriptions.Item>
            <Descriptions.Item label="updated">
              {new Date((record?.updatedAt ?? 0) - 0).toLocaleString("pl")}
            </Descriptions.Item>
          </Descriptions>
        )}
        <Descriptions
          title={
            <>
              <span>Tracing </span>
              <RefreshButton
                loading={traceDataFetch.isLoading}
                onClick={() => traceDataFetch.refetch()}
              ></RefreshButton>
            </>
          }
        >
          <Descriptions.Item label="tracingID">
            {traceData?.traceID}
          </Descriptions.Item>
          <Descriptions.Item label="Jaeger">
            <a
              href={`${process.env.REACT_APP_JAEGER_TRACE_URL}/${traceData?.traceID}`}
              target="_blank"
            >
              show
            </a>
          </Descriptions.Item>
        </Descriptions>
        {traceData && <TracingMain trace={traceData} entryStart={getStart()} />}
        {/* {JSON.stringify(traceData)} */}
        {/* <Trace /> */}
      </Show>
    </>
  );
  // list of keys, popup to create new key etc?
};
