import myDataProvider from "./MyDataProvider";
import { Authenticated, HttpError, Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
  notification,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/reset.css";
import routerProvider from "@pankod/refine-react-router-v6";
import { LoginPage } from "auth/LoginPage";
import CustomLayout from "layout/CustomLayout";
import axios from "axios";
import { ConnectRequestShow } from "connect-request/ConnectRequestShow";
import { ConnectRequestList } from "connect-request/ConnectRequestList";
import { DevicesList } from "devices/DevicesList";
import { BcTransactionList } from "bc-transactions/BcTransactionList";
import { BcTransactionShow } from "bc-transactions/BcTransactionShow";
import { authSchema } from "auth/auth-schema";
import { authProvider } from "auth/authProvider";
import { DeviceShow } from "devices/DeviceShow";
import { ConnectRequestCreate } from "connect-request/ConnectRequestCreate";
// provide custom axios instance with auth for provider

const createAxiosInstanceWApikey = (params: {
  apikey?: string;
  authSchemaKey?: string;
}) => {
  const axiosInstance = axios.create();
  axiosInstance.interceptors.request.use(
    // Here we can perform any function we'd like on the request
    (request: any /*AxiosRequestConfig*/) => {
      // Retrieve the token from local storage
      // Check if the header property exists

      const authData = localStorage.getItem("refine-auth");
      const token =
        authData && params.authSchemaKey
          ? JSON.parse(authData)[params.authSchemaKey]
          : "";

      if (request.headers) {
        // Set the Authorization header if it exists
        request.headers["X-API-KEY"] = params.apikey ?? token;
      } else {
        // Create the headers property if it does not exist
        request.headers = {
          "X-API-KEY": params.apikey ?? token,
        };
      }

      return request;
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error.response);
      const customError = {
        ...error,
        message:
          error.response?.data?.message ??
          error.response?.data?.message?.join(", \n"),
        statusCode: error.response?.status,
      };

      return Promise.reject(customError);
    }
  );
  return axiosInstance;
};

notification.config({ duration: 15, maxCount: 3 });

function App() {
  return (
    <Refine
      notificationProvider={notificationProvider}
      Layout={CustomLayout}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      routerProvider={{
        ...routerProvider,
        // routes: [
        //   {
        //     element: <Dashboard />,
        //     path: "/dashboard",
        //     layout: true,
        //   },
        // ],
      }}
      dataProvider={{
        default: myDataProvider(
          process.env.REACT_APP_RCON_API_URL!,
          createAxiosInstanceWApikey({
            authSchemaKey: authSchema.reconnectApiKey.name,
          })
        ),
        aggregator: myDataProvider(
          process.env.REACT_APP_AGGR_API_URL!,
          createAxiosInstanceWApikey({
            authSchemaKey: authSchema.aggregatorApiKey.name,
          })
        ),
      }}
      authProvider={authProvider}
      LoginPage={LoginPage}
      resources={[
        // {
        //   name: "dashboard",
        //   options: { label: "Dashboard" },
        //   list: () => null,
        // },
        {
          name: "bc-transactions",
          list: BcTransactionList,
          // create: BcTransactionCreate,
          // edit: BcTransactionEdit,
          show: BcTransactionShow,
          options: { dataProviderName: "aggregator" },
        },
        {
          name: "connect-requests",
          list: ConnectRequestList,
          create: ConnectRequestCreate,
          // edit: BcTransactionEdit,
          show: ConnectRequestShow,
        },
        {
          name: "devices",
          list: DevicesList,
          show: DeviceShow,
        },

        // {
        //   name: "devices",
        //   list: DeviceList,
        //   create: DeviceCreate,
        //   edit: DeviceEdit,
        //   show: DeviceShow,
        // },
      ]}
    ></Refine>
  );
}

export default App;
