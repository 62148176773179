import { Button, Popover, RefreshButton } from "@pankod/refine-antd";
import { EnumWithTranslation } from "common/EnumWithTranslation";
import { ResourceListFilter } from "common/resource-list/ResourceListFilter";
import { ResourceListItem } from "common/resource-list/ResourceListItem";
import { ShortenedWithExplorerUrl } from "common/ShortenedWithExplorerUrl";
import { TextWithWhitespaces } from "common/TextWithWhitespaces";
import { ToggleEnable } from "common/ToggleEnabled";
import {
  bcStatusTrans,
  BcTxStatus,
  BcTxStatusList,
  iotStatusTrans,
  IotTxStatus,
  IotTxStatusList,
} from "./BcTransEnums";

export interface IBcTransaction {
  id: number;
  txid: string;
  device?: string;
  sender?: string;
  organisation?: string;
  tx: string;
  txStatus: BcTxStatus;
  iotStatus: IotTxStatus;
  txerror?: string;
  createdAt: number;
  updatedAt: number;
}

export const bcTransactionListProps: ResourceListItem<IBcTransaction>[] = [
  "id",
  {
    type: "custom",
    dataIndex: "txid",
    renderer(data, record, index) {
      return <ShortenedWithExplorerUrl str={data} path={"/tx/"} />;
    },
  },
  {
    type: "custom",
    dataIndex: "device",
    renderer(data, record, index) {
      return <ShortenedWithExplorerUrl str={data} path={"/address/"} />;
    },
  },
  {
    type: "custom",
    dataIndex: "sender",
    renderer(data, record, index) {
      return <ShortenedWithExplorerUrl str={data} path={"/address/"} />;
    },
  },
  {
    type: "custom",
    dataIndex: "organisation",
    renderer(data, record, index) {
      return <ShortenedWithExplorerUrl str={data} path={"/address/"} />;
    },
  },
  {
    type: "custom",
    dataIndex: "tx",
    renderer(data, record, index) {
      return (
        <Popover
          content={
            <TextWithWhitespaces
              text={JSON.stringify(JSON.parse(data), undefined, 4)}
            />
          }
          trigger="click"
        >
          <Button>Tx</Button>
        </Popover>
      );
    },
  },
  {
    type: "custom",
    dataIndex: "txStatus",
    renderer(data, record, index) {
      return <EnumWithTranslation value={data} translations={bcStatusTrans} />;
    },
  },
  {
    type: "custom",
    dataIndex: "iotStatus",
    renderer(data, record, index) {
      return <EnumWithTranslation value={data} translations={iotStatusTrans} />;
    },
  },
  "txError",
  { type: "date", dataIndex: "createdAt" },
  { type: "date", dataIndex: "updatedAt" },
];

export const bcTransactionListSearchProps: ResourceListFilter[] = [
  "txid",
  "device",
  "sender",
  "organisation",
  {
    type: "enum",
    name: "Tx status",
    dataIndex: "txStatus",
    transl: BcTxStatusList.map((x: string) => ({
      name: bcStatusTrans[x as BcTxStatus]?.text ?? "",
      val: x,
    })),
  },
  {
    type: "enum",
    name: "Iot status",
    dataIndex: "iotStatus",
    transl: IotTxStatusList.map((x: string) => ({
      name: iotStatusTrans[x as IotTxStatus]?.text ?? "",
      val: x,
    })),
  },
  "txerror",
  "tx",
];
