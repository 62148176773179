import moment from "moment";
import "moment.distance";

export const DistInTime = (props: { time: number }) => {
  const now = Date.now() / 1000;
  const executed = props.time / 1000;
  return (
    <>
      {(moment as any)
        .duration(
          moment
            .unix(now < executed ? executed : now)
            .diff(moment.unix(now < executed ? now : executed))
        )
        .distance()}
      {now > executed && <> ago</>}
    </>
  );
};
