import { Button } from "@pankod/refine-antd";
import { ResourceListFilter } from "common/resource-list/ResourceListFilter";
import { ResourceListItem } from "common/resource-list/ResourceListItem";

export interface IDevice {
  id: number;
  address: string;
  name: string;
  imei: string;
  provider: string;
  connected: boolean;
  lastChange: number;
}

export const deviceListProps: ResourceListItem<IDevice>[] = [
  "id",
  "address",
  "imei",
  "provider",
  "name",
  { type: "boolean", dataIndex: "connected" },
  { type: "date", dataIndex: "lastChange" },
];

export const deviceListSearchProps: ResourceListFilter[] = [
  "id",
  "address",
  "imei",
  "provider",
  "name",
  { type: "gte", dataIndex: "lastChange", valueType: "date", initial: 0 },
  {
    type: "lte",
    dataIndex: "lastChange",
    valueType: "date",
    initial: Date.now() + 100000,
  },
  { type: "boolean", dataIndex: "connected" },
];
