export enum ConnectSmsStatus {
  unknown = "unknown",
  failSend = "failSend",
  sent = "sent",
  failDeliver = "failDeliver",
  delivered = "delivered",
}

export const ConnectSmsStatusList = [
  "unknown",
  "failSend",
  "sent",
  "failDeliver",
  "delivered",
];

export const ConnectSmsStatusTrans: {
  [key in ConnectSmsStatus]: { color?: string; text?: string };
} = {
  unknown: { color: "#aa0", text: "Unknown" },
  failSend: { color: "red", text: "Fail send" },
  failDeliver: { color: "red", text: "Fail deliver" },
  sent: { color: "#aa0", text: "Sent" },
  delivered: { color: "green", text: "Delivered" },
};
