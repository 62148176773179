import {
  useForm,
  Form,
  Create,
  Edit,
  Input,
  InputNumber,
  Checkbox,
  Show,
  Descriptions,
  Divider,
  Button,
  RefreshButton,
} from "@pankod/refine-antd";
import { useApiUrl, useCustom, useShow } from "@pankod/refine-core";
import { EnumWithTranslation } from "common/EnumWithTranslation";
import { ResourceList } from "common/resource-list/ResourceList";
import {
  connectSmsListProps,
  connectSmsListSearchProps,
} from "connect-sms/ConnectSmsInterfaces";
import { ConnectRequestStatusTrans } from "./ConnectRequestEnums";
import { IConnectRequest } from "./ConnectRequestInterfaces";
// import Trace from "jaeger-react-trace-component";

export const ConnectRequestShow = () => {
  const { queryResult } = useShow<IConnectRequest>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <>
      <Show>
        {!isLoading && (
          <>
            <Descriptions title={"Aggregated"} column={1}>
              <Descriptions.Item label="Status">
                <EnumWithTranslation
                  translations={ConnectRequestStatusTrans}
                  value={record?.status ?? ""}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Connect statutes before timestamp">
                {new Date(
                  (record?.includeAllBeforeTstamp ?? 0) - 0
                ).toLocaleString("pl")}
              </Descriptions.Item>
              <Descriptions.Item label="created">
                {new Date((record?.createdAt ?? 0) - 0).toLocaleString("pl")}
              </Descriptions.Item>
              <Descriptions.Item label="updated">
                {new Date((record?.updatedAt ?? 0) - 0).toLocaleString("pl")}
              </Descriptions.Item>
            </Descriptions>
            <ResourceList
              resource="connect-sms"
              listProps={connectSmsListProps}
              listSearchProps={connectSmsListSearchProps}
              filter={{
                field: "connectRequest",
                operator: "eq",
                value: record?.id ?? 0,
              }}
              hideCreate
              hideEdit
              hideShow
            />
          </>
        )}
      </Show>
    </>
  );
  // list of keys, popup to create new key etc?
};
