import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  EditButton,
  Table,
  useTable,
  Form,
  Space,
  CreateButton,
  Row,
  ShowButton,
  Button,
} from "@pankod/refine-antd";
import {
  CrudFilter,
  CrudFilters,
  HttpError,
  useNavigation,
  useResource,
  useShow,
} from "@pankod/refine-core";
import { ReactNode, useState } from "react";
import { filterRender } from "./FilterRender";
import { listEntryRender } from "./ListEntryRender";
import { ResourceListFilter } from "./ResourceListFilter";
import { ResourceListItem } from "./ResourceListItem";

export type ResourceListProps<ResourceInterface> = {
  listProps: ResourceListItem<ResourceInterface>[];
  listSearchProps: ResourceListFilter[];
  resource?: string;
  dataProviderName?: string;
  filter?: CrudFilter[] | CrudFilter; //{ field: string; value: number | string; operator: "eq" };
  perPage?: number;
  hideCreate?: true;
  hideShow?: true;
  hideEdit?: true;
  noUrlModification?: true;
  editClickAction?: (id: number) => void;
  createClickAction?: () => void;
  customClick?: {
    label: ReactNode | ((record: ResourceInterface) => ReactNode);
    callback: (record: ResourceInterface) => void;
  }[];
};

export const ResourceList /*: React.FC<ResourceListProps<ResourceInterface>>*/ =
  <ResourceInterface extends { id: any }>(
    props: ResourceListProps<ResourceInterface>
  ) => {
    // ================= table settings, filtering etc
    const { tableProps, sorter, filters, searchFormProps } = useTable<
      ResourceInterface,
      HttpError
    >({
      dataProviderName: props.dataProviderName,
      resource: props.resource,
      hasPagination: true,
      syncWithLocation: !props.noUrlModification,
      ignoreLocation: !!props.noUrlModification,

      initialPageSize: props.perPage,
      initialSorter: [{ field: "id", order: "desc" }],
      permanentFilter: Array.isArray(props.filter)
        ? props.filter
        : [...(props.filter ? [props.filter] : [])],

      onSearch: (params: any) => {
        console.log({ params, prov: props.dataProviderName });
        const filters: CrudFilters = [];

        filters.push(
          ...Object.entries(params).map((x) => ({
            field: (x[0] as string).split("_")[0], // ignore everything after "_"
            operator: mapSearchOperatorToFilter(x[0]), // added support on backend side
            value: x[1] as string,
          }))
        );
        return filters;
      },
    });

    // ================== navigation - to support multiple providers

    const navigation = useNavigation();
    const resource = useResource(); // current resource - if none specified (used for navigation actions)

    //=================== filter utils

    const filterIdModifier = (filter: ResourceListFilter) => {
      if (typeof filter === "string") return "";
      switch (filter.type) {
        case "lte":
        case "gte":
          return "_" + filter.type;
      }
      return "";
    };

    const mapSearchOperatorToFilter = (prop: string): any => {
      for (const iterator of props.listSearchProps) {
        if (typeof iterator == "string") {
          if (prop == iterator) return "contains";
        } else {
          if (prop != iterator.dataIndex + filterIdModifier(iterator)) continue;
          switch (iterator.type) {
            case "boolean":
            case "enum":
              return "eq";
            case "lte":
            case "gte":
              return iterator.type;
            default:
              return "eq";
          }
        }
      }
      return "contains";
    };

    //===================  render

    return (
      <>
        {/* Search form */}
        <Row align="middle" justify="space-between">
          <Form
            {...searchFormProps}
            layout="vertical"
            onValuesChange={() => searchFormProps.form?.submit()}
          >
            <Space wrap>
              {props.listSearchProps.map((x) =>
                filterRender(x, props.noUrlModification ? undefined : filters)
              )}
            </Space>
          </Form>
          {!props.hideCreate && (
            <CreateButton
              resourceNameOrRouteName={props.resource}
              onClick={
                props.createClickAction
                  ? () => props.createClickAction!()
                  : undefined
              }
            />
          )}
        </Row>

        {/* Table  */}
        <Table {...tableProps} showSorterTooltip={false} rowKey="id">
          {props.listProps.map((x) => listEntryRender(x, sorter))}
          <Table.Column
            render={(item, record: ResourceInterface) => (
              <>
                {!props.hideShow && (
                  <Button
                    icon={<EyeOutlined />}
                    onClick={(e) => {
                      e.preventDefault();
                      navigation.show(
                        props.resource ?? resource.resourceName,
                        record.id
                      );
                    }}
                    href={navigation.showUrl(
                      props.resource ?? resource.resourceName,
                      record.id
                    )}
                  >
                    Show
                  </Button>
                )}
                {!props.hideEdit && (
                  <Button
                    icon={<EditOutlined />}
                    onClick={
                      props.editClickAction
                        ? (e) => {
                            e.preventDefault();
                            props.editClickAction!(record.id); // could return item instead of id...
                          }
                        : (e) => {
                            e.preventDefault();
                            navigation.edit(
                              props.resource ?? resource.resourceName,
                              record.id
                            );
                          }
                    }
                    href={
                      props.editClickAction
                        ? ""
                        : navigation.editUrl(
                            props.resource ?? resource.resourceName,
                            record.id
                          )
                    }
                  >
                    Edit
                  </Button>
                )}
                {props.customClick &&
                  props.customClick.map((custom, k) => (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        custom.callback(record);
                      }}
                      key={k}
                    >
                      {typeof custom.label == "function"
                        ? custom.label(record)
                        : custom.label}
                    </Button>
                  ))}
              </>
            )}
          />
        </Table>
      </>
    );
  };
