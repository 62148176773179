export enum ConnectRequestStatus {
  unknown = "unknown",
  running = "running",
  done = "done",
  fail = "fail",
  cancelled = "cancelled",
}

export const ConnectRequestStatusList = [
  "unknown",
  "running",
  "done",
  "fail",
  "cancelled",
];

export const ConnectRequestStatusTrans: {
  [key in ConnectRequestStatus]: { color?: string; text?: string };
} = {
  unknown: { color: "#aa0", text: "Unknown" },
  fail: { color: "red", text: "Fail" },
  cancelled: { color: "red", text: "Cancelled" },
  done: { color: "green", text: "Done" },
  running: { color: "#0f5", text: "Running" },
};
