import { List, RefreshButton } from "@pankod/refine-antd";
import { useInvalidate } from "@pankod/refine-core";
import { ResourceList } from "common/resource-list/ResourceList";
import {
  connectRequestListProps,
  connectRequestListSearchProps,
} from "./ConnectRequestInterfaces";

export const ConnectRequestList: React.FC = () => {
  const invalidate = useInvalidate();
  const refreshList = () => {
    invalidate({ resource: "connect-requests", invalidates: ["list"] });
  };

  return (
    <List
      headerButtons={
        <RefreshButton
          onClick={() => {
            refreshList();
          }}
        ></RefreshButton>
      }
    >
      <ResourceList
        listProps={connectRequestListProps}
        listSearchProps={connectRequestListSearchProps}
        // hideCreate
        hideEdit
        // hideShow
      />
    </List>
  );
};
