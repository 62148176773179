import {
  Show,
  Descriptions,
  Divider,
  BooleanField,
  Button,
} from "@pankod/refine-antd";
import {
  useApiUrl,
  useCustomMutation,
  useInvalidate,
  useShow,
} from "@pankod/refine-core";
import { BcDiag } from "bc-diag/BcDiag";
import {
  bcTransactionListProps,
  bcTransactionListSearchProps,
} from "bc-transactions/BcTransactionInterfaces";
import { DistInTime } from "common/DistInTime";
import { ResourceList } from "common/resource-list/ResourceList";
import { IConnectRequest } from "connect-request/ConnectRequestInterfaces";
import {
  connectSmsListProps,
  connectSmsListSearchProps,
} from "connect-sms/ConnectSmsInterfaces";
import { IDevice } from "./DevicesInterfaces";

export const DeviceShow = () => {
  const invalidate = useInvalidate();
  const { queryResult } = useShow<IDevice>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const apiUrl = useApiUrl();

  const { mutate } = useCustomMutation<IConnectRequest>();

  const connect = () => {
    if (window.confirm("Connect device?")) {
      mutate({
        // resource: "conenction-request",
        url: apiUrl + "/connect-disconnected",
        method: "post",
        values: { addresses: [record?.address] } as IConnectRequest,
        successNotification(data: any, values, resource) {
          invalidate({ resource: "connect-sms", invalidates: ["list"] });
          return {
            message: `request created ${data?.data?.id}`,
            type: "success",
            undoableTimeout: 20010,
          };
        },
      });
    }
  };

  return (
    <>
      <Show>
        {!isLoading && (
          <>
            <Descriptions>
              <Descriptions.Item label="Address">
                {record?.address}
              </Descriptions.Item>
              <Descriptions.Item label="Name">{record?.name}</Descriptions.Item>
              <Descriptions.Item label="imei">{record?.imei}</Descriptions.Item>
              <Descriptions.Item label="Provider">
                {record?.provider}
              </Descriptions.Item>
              <Descriptions.Item
                contentStyle={{ color: record?.connected ? "green" : "red" }}
                label="Connected"
              >
                <BooleanField value={record?.connected} />
              </Descriptions.Item>
              <Descriptions.Item label="Last change">
                {new Date(record?.lastChange! - 0).toLocaleString("pl")} (
                <DistInTime time={record?.lastChange!} />)
              </Descriptions.Item>
              <Descriptions.Item label="Actions">
                <Button onClick={() => connect()} size="small">
                  Connect
                </Button>
              </Descriptions.Item>
              {/* <Descriptions.Item label="Bc status"></Descriptions.Item> */}
            </Descriptions>
            <Divider>Blockchain status</Divider>
            <BcDiag address={record?.address ?? ""} />
            <Divider>Connect requests for device</Divider>
            <ResourceList
              resource="connect-sms"
              listProps={connectSmsListProps}
              listSearchProps={connectSmsListSearchProps}
              hideCreate
              perPage={3}
              hideEdit
              hideShow
              filter={{
                field: "imei",
                operator: "eq",
                value: record?.imei ?? "",
              }}
            />
            <Divider>Transactions with device</Divider>
            <ResourceList
              resource="bc-transactions"
              dataProviderName="aggregator"
              listProps={bcTransactionListProps}
              listSearchProps={bcTransactionListSearchProps}
              hideCreate
              hideEdit
              noUrlModification
              filter={{
                field: "device",
                operator: "eq",
                value: record?.address ?? "",
              }}
            />
          </>
        )}
      </Show>
    </>
  );
};
