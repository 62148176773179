import { AntdLayout, Sider } from "@pankod/refine-antd";
import React from "react";

const CustomLayout = ({ children }: any) => (
  <AntdLayout style={{ minHeight: "100vh", height: "max-content" }}>
    <Sider />
    <AntdLayout>
      <AntdLayout.Header color="red"></AntdLayout.Header>
      <AntdLayout.Content>
        <div style={{ padding: 24 }}>{children}</div>
      </AntdLayout.Content>
    </AntdLayout>
  </AntdLayout>
);

export default CustomLayout;
