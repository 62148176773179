import { Popover } from "@pankod/refine-antd";
import { DaysOfWeek } from "app-constants";

export const camelToSpaced = (camelCaseString: string) =>
  camelCaseString
    .replace(/([A-Z]\w)/g, (c) => ` ${c.toLowerCase()}`)
    .replaceAll(".", " > ");

export function resolveInObj(path: string, obj: object, separator = ".") {
  var properties = (Array.isArray(path) ? path : path.split(separator)) as any;
  return properties.reduce((prev: any, curr: any) => prev?.[curr], obj);
}
export const timeDelayFormatter = (delay: number, showHms = true) => {
  const date = new Date(delay);
  return `${date.getHours() - 1}:${date
    .getMinutes()
    .toString()
    .padStart(2, "00")}${showHms ? " (h:m)" : ""}`;
};

export const trigerTimeFormatter = (delay: number, showHms = true) => {
  const date = new Date(delay * 60000);
  return `${DaysOfWeek[Math.floor(delay / (60 * 24))]} ${date.getHours()}:${date
    .getMinutes()
    .toString()
    .padStart(2, "00")}${showHms ? " (h:m)" : ""}`;
};

export const ProgressRange = (props: {
  start: number;
  stop: number;
  total: number;
  points?: { point: number; label: string }[];
}) => {
  // if(props.start<=props.stop)
  let start = props.start;
  let stop = props.stop;
  let diff = stop - start;
  const min = 0.008; // 1 => 100%  (minimum progress width)

  if (diff / props.total < min) {
    start -= (props.total * min - diff) * 0.5;
    stop += (props.total * min - diff) * 0.5;
  }
  const left = Math.max(0, (start / props.total) * 100);
  const right = Math.max(0, ((props.total - stop) / props.total) * 100);

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 10,
          background: "#eee",
          borderRadius: 100,
        }}
      >
        <div
          style={{
            background: "#67be23",
            position: "absolute",
            top: 0,
            bottom: 0,
            borderRadius: 100,
            left: `${left}%`,
            right: `${right}%`,
            borderColor: "#8d4",
            borderWidth: 2,
            borderStyle: "solid",
          }}
        />
        {props.points &&
          props.points.map((x) => {
            return (
              <ProgressPoint total={props.total} at={x.point} label={x.label} />
            );
          })}
        <ProgressRangeTicks total={props.total} />
      </div>
    </>
  );
};

export const ProgressRangeTicks = (props: { total: number }) => {
  const mult = 1000;
  const oneS = mult * 1000;
  const hundredMs = mult * 100;

  const ticksBy = props.total < oneS * 10 ? hundredMs : 10000000;

  const ticks = new Array(Math.ceil(props.total / ticksBy))
    .fill(0)
    .map((v, k) => ({
      offs: ((k * ticksBy) / props.total) * 100,
      val: k * ticksBy,
    }));

  const valDisp = (val: number) => {
    if (val > props.total - ticksBy / 3) return "";
    // if (ticksBy < 10000000) {
    // return `${val / 1000}ms`;
    // }
    return `${val / 1000000}s`;
  };

  return (
    <>
      {ticks.map((x) => (
        <div
          style={{
            background: "rgba(0,0,0,0.05)",
            position: "absolute",
            top: -3,
            bottom: -3,
            left: `${x.offs}%`,
            width: 1,
            // right: `${right}%`,
            // borderColor: "#050",
            // borderWidth: 2,
            // borderStyle: "solid",
          }}
        >
          <span
            style={{
              position: "absolute",
              fontSize: 9,
              top: 11,
              color: "#bbb",
            }}
          >
            {valDisp(x.val)}
          </span>
        </div>
      ))}
    </>
  );
};

export const ProgressPoint = (props: {
  total: number;
  at: number;
  label: string;
}) => {
  const at = (props.at / props.total) * 100;

  return (
    <Popover content={props.label} trigger="hover">
      <div
        style={{
          background: "#67be23",
          position: "absolute",
          top: 0,
          bottom: 0,
          borderRadius: 100,
          left: `${at}%`,
          width: 10,
          // right: `${right}%`,
          borderColor: "#050",
          borderWidth: 2,
          borderStyle: "solid",
        }}
      ></div>
    </Popover>
  );
};
