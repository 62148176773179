import React from "react";
import { LoginPageProps } from "@pankod/refine-core";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
} from "antd";
import { useLogin, useTranslate } from "@pankod/refine-core";

import logo from "../images/skey-logo.svg";
import {
  containerStyles,
  image,
  imageContainer,
  layoutStyles,
  titleStyles,
} from "./styles";
import { authSchema } from "./auth-schema";

const { Text, Title } = Typography;
export interface ILoginForm {
  apiKey: string;
}

/**
 * @see {@link https://refine.dev/docs/api-references/components/refine-config#loginpage} for more details.
 */
export const LoginPage: React.FC<LoginPageProps> = () => {
  const [form] = Form.useForm<ILoginForm>();
  const translate = useTranslate();

  const { mutate: login, isLoading } = useLogin<ILoginForm>();

  const CardTitle = (
    <Title level={3} style={titleStyles}>
      {translate("pages.login.title", "Sign in your account")}
    </Title>
  );

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div style={containerStyles}>
            <div style={imageContainer}>
              <img src={logo} alt="Skey Logo" style={image} />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login(values);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                  apiKey: "",
                }}
              >
                {Object.values(authSchema).map((x) => (
                  <Form.Item
                    name={x.name}
                    label={translate(x.name + " is required", x.name)}
                    rules={[{ required: x.required }]}
                  >
                    <Input
                      size="large"
                      type={x.passInput ? "password" : undefined}
                      placeholder={translate("pages.login.username", x.name)}
                    />
                  </Form.Item>
                ))}

                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  block
                >
                  {translate("pages.login.signin", "Sign in")}
                </Button>
              </Form>
              {/* <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  {translate("pages.login.noAccount", "Don’t have an account?")}{" "}
                  <a href="#" style={{ fontWeight: "bold" }}>
                    {translate("pages.login.signup", "Sign up")}
                  </a>
                </Text>
              </div> */}
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
