// import { useForm, useSelect } from "@pankod/refine-core";
import {
  Checkbox,
  Create,
  DatePicker,
  Form,
  Input,
  useForm,
} from "@pankod/refine-antd";
import { FormTimestampConverterDayjs } from "common/forms/FormTimestampConverterDayjs";
import { IConnectRequest } from "./ConnectRequestInterfaces";

const ONE_MONTH = 1000 * 60 * 60 * 24 * 30;

export const ConnectRequestCreate = () => {
  const { formProps, saveButtonProps } = useForm<IConnectRequest>({
    redirect: "show",
  });
  // const deviceSelect = useSelect<IDevice>({
  //   resource: "users",
  //   optionLabel: "name",
  //   optionValue: "id",
  //   defaultValue: [],
  //   onSearch: (value) => [
  //     {
  //       field: "name",
  //       operator: "contains",
  //       value,
  //     },
  //   ],
  // });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        {form()}
      </Form>
    </Create>
  );
};

const form = () => (
  <>
    <Form.Item
      name="excludeAllBeforeTstamp"
      label="Last state change not lower than"
      initialValue={Date.now() - ONE_MONTH}
    >
      <FormTimestampConverterDayjs>
        <DatePicker showTime />
      </FormTimestampConverterDayjs>
    </Form.Item>
    <Form.Item
      name="includeAllBeforeTstamp"
      label="Last state change not higher than"
      initialValue={Date.now()}
    >
      <FormTimestampConverterDayjs>
        <DatePicker showTime />
      </FormTimestampConverterDayjs>
    </Form.Item>
    {/* <Form.Item name="providers" label="Providers (separated by ,)">
      <Input />
    </Form.Item> */}
    {/* <Form.Item name="minOfWeek" label="Time to trigger"> */}

    {/* </Form.Item> */}

    {/* <Form.Item name="users" label="User">
      <Select {...userSelectProps} mode="multiple" />
    </Form.Item> */}
  </>
);
