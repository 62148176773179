import { ResourceListFilter } from "./ResourceListFilter";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
} from "@pankod/refine-antd";
import { FormTimestampConverterDayjs } from "common/forms/FormTimestampConverterDayjs";
import { CrudFilters } from "@pankod/refine-core";

export const filterRender = (
  filterItem: ResourceListFilter,
  filters: CrudFilters | undefined
) => {
  console.log(filters);
  const name =
    typeof filterItem == "string" ? filterItem : filterItem.dataIndex;
  const initial = filters?.find((x) => (x as any)?.field == name)?.value;

  if (typeof filterItem == "string") {
    return (
      <Form.Item
        label={filterItem}
        name={filterItem}
        key={filterItem}
        initialValue={initial}
      >
        <Input />
      </Form.Item>
    );
  }
  switch (filterItem.type) {
    case "boolean":
      return (
        <Form.Item
          label={filterItem.name ?? filterItem.dataIndex}
          name={filterItem.dataIndex}
          key={filterItem.dataIndex}
          initialValue={initial}
        >
          <Select
            style={{ width: 70 }}
            options={[
              { label: "", value: "" },
              { label: "yes", value: "true" },
              { label: "no", value: "false" },
            ]}
          />
        </Form.Item>
      );
    case "enum":
      return (
        <Form.Item
          label={filterItem.name ?? filterItem.dataIndex}
          name={filterItem.dataIndex}
          key={filterItem.dataIndex}
          initialValue={initial}
        >
          <Select
            style={{ width: 100 }}
            options={[{ label: "", value: "" }].concat(
              filterItem.transl.map((x) => ({
                label: x.name,
                value: x.val.toString(),
              })) as any
            )}
          />
        </Form.Item>
      );
    case "gte":
    case "lte":
      const initialLtGt = filters?.find(
        // initial filter by type
        (x) => (x as any)?.field == name && x.operator == filterItem.type
      )?.value;
      return (
        <Form.Item
          label={
            filterItem.name ??
            LtGtTranslations[filterItem.type] + " " + filterItem.dataIndex
          }
          name={filterItem.dataIndex + "_" + filterItem.type}
          key={filterItem.dataIndex + "_" + filterItem.type}
          initialValue={initialLtGt ?? filterItem.initial}
        >
          <LtGtFilter range={filterItem.range} type={filterItem.valueType} />
        </Form.Item>
      );
    default:
      return <div style={{ color: "red" }}>unsupported filter</div>;
  }
};

const LtGtTranslations = { lte: "max", gte: "min" };

const LtGtFilter = (props: {
  value?: number;
  onChange?: (val: number | null) => void;
  type?: "number" | "date";
  range?: {
    start?: number;
    stop?: number;
  };
}) => {
  if (!props.type || props.type == "number")
    return (
      <InputNumber
        value={props.value}
        onChange={(val) => (props.onChange ? props.onChange(val) : undefined)}
        min={props.range?.start}
        max={props.range?.stop}
      />
    );
  if (props.type == "date")
    return (
      <FormTimestampConverterDayjs
        value={props.value}
        onChange={(val) => (props.onChange ? props.onChange(val) : undefined)}
      >
        <DatePicker />
      </FormTimestampConverterDayjs>
    );

  return <>"Unsupported"</>;
};
