export type loginParams = {
  type: "apiKey"; // for now only api keys are supported
  name: string;
  passInput?: true; // if api key field should be masked
  required?: boolean; // if can be skipt eg when there is one panel for multiple apis
  checkUrl?: string; // url for api key check, eg some index etc
};

export const authSchema = {
  aggregatorApiKey: {
    type: "apiKey",
    name: "aggregator-api-key",
    passInput: true,
    required: true,
    checkUrl: process.env.REACT_APP_AGGR_API_URL + "/bc-transactions",
  } as loginParams,
  reconnectApiKey: {
    type: "apiKey",
    name: "reconnect-api-key",
    passInput: true,
  } as loginParams,
};
