import { Checkbox, getDefaultSortOrder, Table } from "@pankod/refine-antd";
import { CrudSorting } from "@pankod/refine-core";
import { camelToSpaced } from "utils";
import { ResourceListItem } from "./ResourceListItem";

export const listEntryRender = <ResourceInterface,>(
  listItem: ResourceListItem<ResourceInterface>,
  sorter: CrudSorting | undefined
) => {
  if (typeof listItem == "string")
    return (
      <Table.Column
        key={listItem}
        dataIndex={listItem.split(".")}
        title={camelToSpaced(listItem)}
        sorter
        defaultSortOrder={getDefaultSortOrder(listItem, sorter)}
        render={(data, record: any, index) => {
          if (typeof data == "boolean") return <Checkbox checked={data} />;
          return data;
        }}
      />
    );

  switch (listItem.type) {
    case "boolean":
      return TableItemCustom(
        sorter,
        listItem.dataIndex,
        (data, record) => <Checkbox checked={data} />,
        listItem.name
      );

    case "date":
      return TableItemCustom(
        sorter,
        listItem.dataIndex,
        (data, record) => (
          <>
            {data ? new Date(Number.parseInt(data)).toLocaleString("pl") : "-"}
          </>
        ),
        listItem.name
      );
    case "ref":
      return TableItemCustom(
        sorter,
        listItem.dataIndex,
        (data, record) => {
          const id = listItem.resIdFetch(record);

          return (
            <a href={`/${listItem.resource}/${listItem.action}/${id}`}>
              {data}
            </a>
          );
        },
        listItem.name
      );
    case "custom":
      return TableItemCustom(
        sorter,
        listItem.dataIndex,
        (data, record, index) => listItem.renderer(data, record, index),
        listItem.name
      );
    default:
      throw new Error("not yet implemented");
  }
};

const TableItemCustom = (
  sorter: CrudSorting | undefined,
  dataIndex: string,
  renderer: (data: any, record: any, index?: any) => JSX.Element,
  name?: string
) => {
  return (
    <Table.Column
      key={dataIndex}
      dataIndex={dataIndex.split(".")}
      title={name ?? camelToSpaced(dataIndex)}
      sorter
      defaultSortOrder={getDefaultSortOrder(dataIndex, sorter)}
      render={(data, record) => renderer(data, record)}
    />
  );
};
