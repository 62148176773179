import { cloneElement, ReactElement } from "react";
import dayjs from "dayjs";

export const FormTimestampConverterDayjs = (props: {
  value?: number;
  onChange?: (value: number) => void;
  children: ReactElement;
}) => {
  console.log({ val: props.value });
  const convertedValue = dayjs.unix((props.value ?? 0) / 1000); //.Dayjs( //moment.unix((props.value ?? 0) / 1000);
  const onChangeConvert = (val: dayjs.Dayjs) => {
    console.log({ val, hanlder: props.onChange });
    if (props.onChange) props.onChange(val.unix() * 1000);
  };

  return cloneElement(props.children, {
    value: convertedValue,
    onChange: (val: any) => onChangeConvert(val),
  });
};
