export enum BcTxStatus {
  created = "created",
  inBlockchain = "inBlockchain",
  broadcastError = "broadcastError",
  notFoundInBc = "notFoundInBc",
}

export const BcTxStatusList = [
  "created",
  "inBlockchain",
  "broadcastError",
  "notFoundInBc",
];

export const bcStatusTrans: {
  [key in BcTxStatus]: { color?: string; text?: string };
} = {
  broadcastError: { color: "red", text: "Broadcast err" },
  created: { color: "#aa0", text: "Created" },
  inBlockchain: { color: "green", text: "Stored" },
  notFoundInBc: { color: "red", text: "Not found" },
};

export enum IotTxStatus {
  unknown = "unknown",
  ok = "ok",
  device_timeout = "device_timeout",
  command_expired = "command_expired",
  device_disconnected = "device_disconnected",
}

export const IotTxStatusList = [
  "unknown",
  "ok",
  "device_timeout",
  "command_expired",
  "device_disconnected",
];

export const iotStatusTrans: {
  [key in IotTxStatus]: { color?: string; text?: string };
} = {
  command_expired: { color: "red", text: "Received expired" },
  device_disconnected: { color: "red", text: "Dev disconected" },
  device_timeout: { color: "red", text: "Dev timeout" },
  ok: { color: "green", text: "Ok" },
  unknown: { color: "#aa0", text: "Unknown" },
};
