import { LoadingOutlined } from "@ant-design/icons";
import {
  BooleanField,
  Descriptions,
  Popover,
  Space,
} from "@pankod/refine-antd";
import { useApiUrl, useCustom } from "@pankod/refine-core";
import { TextWithWhitespaces } from "common/TextWithWhitespaces";
import { useEffect, useState } from "react";
import { DefinedDeviceScript, DevDiagResultType } from "./DevDiagResultType";

export const BcDiag = (props: { address: string }) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setEnabled(true);
    }, 0.5);
  }, []);
  const apiUrl = useApiUrl();
  const { data, isLoading } = useCustom<{
    result: DevDiagResultType;
    definedDeviceScripts: DefinedDeviceScript[];
  }>({
    method: "post",
    url: apiUrl + "/diagnose/" + props.address,

    queryOptions: {
      enabled,
    },
  });

  console.log("Render diag with", props);

  const diagResult: DevDiagResultType | undefined = data?.data?.result;
  const keys = Object.entries(diagResult?.keys?.list ?? {});
  const keysStatus = keys.reduce<boolean>(
    (x: any, y: any) => x && y[1].assetType.status && y[1].data.status,
    true
  );

  const scriptInfo =
    data?.data?.definedDeviceScripts && diagResult?.script.hash
      ? data.data.definedDeviceScripts.find((x) =>
          x.blakes.indexOf(diagResult?.script.hash ?? "")
        )
      : undefined;

  const scriptError = !diagResult?.script.hash
    ? "script not set"
    : !diagResult?.script.status
    ? "unknown script"
    : null;

  const errors = diagResult
    ? [
        ...(diagResult?.data.errors ?? []),
        ...(scriptError ? [scriptError] : []),
        ...keys.reduce(
          (x: any[], y: any) => [...x, ...y[1].assetType.errors],
          []
        ),
      ]
    : [];

  // const isOk = diagResult?.data.status && diagResult.script.status;

  return (
    <>
      {isLoading && <LoadingOutlined />}
      {!isLoading && (
        <>
          <Descriptions>
            <Descriptions.Item label="Script">
              {diagResult?.script.hash}
              <span
                style={{ color: diagResult?.script.status ? "green" : "red" }}
              >
                <BooleanField value={diagResult?.script.status} />
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Data">
              <span
                style={{ color: diagResult?.data.status ? "green" : "red" }}
              >
                <BooleanField value={diagResult?.data.status} />
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Keys">
              <Popover
                content={keys.map((key) => (
                  <div
                    style={{
                      color:
                        key[1].assetType.status && key[1].data.status
                          ? "green"
                          : "red",
                    }}
                  >
                    {key[0]}
                  </div>
                ))}
              >
                <span style={{ color: keysStatus ? "green" : "red" }}>
                  {keys.length}{" "}
                  <BooleanField mouseEnterDelay={100000} value={keysStatus} />
                </span>
              </Popover>
            </Descriptions.Item>
            {scriptInfo && (
              <Descriptions.Item label="Script features">
                {Object.entries(scriptInfo?.features ?? {}).map((x) => (
                  <>
                    <span style={{ paddingRight: 5 }}>
                      {x[0]}:
                      <span
                        style={{ padding: 5, color: x[1] ? "green" : "red" }}
                      >
                        <BooleanField value={x[1]} />
                      </span>
                    </span>
                  </>
                ))}
              </Descriptions.Item>
            )}
          </Descriptions>
          <div>
            {errors.map((x) => (
              <div style={{ color: "red" }}>{x}</div>
            ))}
          </div>
          {/* <TextWithWhitespaces text={JSON.stringify(data, undefined, 8)} /> */}
        </>
      )}
    </>
  );
};
